import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { masksByCountryCode } from './constants';
import { Input, InputProps } from '../input';
import { useConfig } from '@/shared/hooks';
import { CountryCode } from '@/shared/types';

export type InputPhoneProps = InputProps & {
  type?: 'landline' | 'mobile';
};

export const InputPhone = React.forwardRef<HTMLDivElement, InputPhoneProps>(
  ({ onBlur, onFocus, type = 'mobile', value, ...props }, ref) => {
    const config = useConfig();
    const countryCode = config?.countryCode || CountryCode.LK;
    const mask = masksByCountryCode[countryCode][type];

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onFocus) {
        onFocus(e);
      }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (onBlur) {
        onBlur(e);
      }
    };

    return (
      <MaskedInput
        guide={false}
        InputLabelProps={{ shrink: true }}
        mask={mask}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="tel"
        value={value}
        {...(props as any)}
        render={(maskRef, maskProps) => {
          return <Input {...maskProps} ref={ref} inputRef={maskRef} />;
        }}
      />
    );
  },
);

InputPhone.displayName = 'InputPhone';
