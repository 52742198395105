import React from 'react';
import { Link, LinkProps, Typography, TypographyProps } from '@mui/material';

export const ContactLink = (props: LinkProps) => {
  const { component, variant, color, display, sx, children, ...otherProps } =
    props;

  const contactLinkSXProps: TypographyProps['sx'] = (theme) => ({
    textDecoration: 'none',
    mb: {
      md: 1,
    },
    '&:hover': {
      color: 'neutral.20',
    },
    [theme.breakpoints.down('md')]: {
      borderTop: `1px solid ${theme.palette.primary[50]}`,
      mx: -2,
      p: 3,
    },
  });

  return (
    <Typography
      component={Link}
      variant="text5"
      color="neutral.100"
      display="block"
      sx={contactLinkSXProps}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};
