import React from 'react';
import { CommonApiContext } from '@/shared/contexts/common-api/common-api-context';

export const useCommonApi = () => {
  const context = React.useContext(CommonApiContext);

  if (context === undefined) {
    throw new Error('useCommonApi must be used within a Context Provider');
  }

  return context;
};
