import React, { useEffect } from 'react';
import { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';
import { NextIntlProvider } from 'next-intl';
import { useRouter } from 'next/router';
import { Box, Theme, ThemeProvider } from '@mui/material';
import {
  GoogleTagManager,
  milligram,
  notoSansSinhala,
  useUtmParams,
} from '@/shared/lib';
import { CommonApiContext } from '@/shared/contexts/common-api/common-api-context';
import { CommonApi } from '@/shared/api/common-api';
import {
  COUNTRY_CODE_STORAGE_KEY,
  LANDING_LOCALE_STORAGE_KEY,
  LANDING_REFERRER_STORAGE_KEY,
  LANDING_URL_STORAGE_KEY,
} from '@/shared/constants';
import { CountryCode, ICommonApiData, Locale } from '@/shared/types';
import {
  ammana,
  ceyloan,
  myvay,
  tambadana,
  trustPaisa,
  dhardesh,
} from '@/themes';
import { getConfig } from '@/utils/get-config';
import { RouteChangeProgress } from '@/shared/ui';

import '@/styles/globals.css';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  commonData: ICommonApiData;
};

const themeByCountryCode: Record<CountryCode, Theme> = {
  [CountryCode.LK]: ceyloan,
  [CountryCode.MY]: tambadana,
  [CountryCode.VN]: myvay,
  [CountryCode.IN]: trustPaisa,
  [CountryCode.ID]: ammana,
  [CountryCode.BD]: dhardesh,
};

const CeyloanApp = (props: AppPropsWithLayout) => {
  const { Component, pageProps, commonData } = props;

  // fixme `unexpected default value`
  const { locale = 'si-LK' } = useRouter();

  const utmParams = useUtmParams();

  const getLayout = Component.getLayout ?? ((page) => page);

  const countryCode: CountryCode = commonData.config.countryCode;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    GoogleTagManager.init({
      id: commonData?.config.googleTagManagerId,
    });

    localStorage.setItem(LANDING_URL_STORAGE_KEY, window.location.href);
    localStorage.setItem(LANDING_REFERRER_STORAGE_KEY, document.referrer);

    localStorage.setItem(COUNTRY_CODE_STORAGE_KEY, countryCode);
  }, []);

  useEffect(() => {
    const localesConvertMap: Record<Locale, string> = {
      [Locale.siLK]: 'si',
      [Locale.msMY]: 'ms',
      [Locale.viVN]: 'vi',
      [Locale.idID]: 'id',
      [Locale.bnBD]: 'bn',
      [Locale.en]: 'en',
    };

    const landingLocale =
      locale in localesConvertMap
        ? localesConvertMap[locale as Locale]
        : localesConvertMap.en;

    localStorage.setItem(LANDING_LOCALE_STORAGE_KEY, landingLocale);
  }, [locale]);

  useEffect(() => {
    if (utmParams) {
      GoogleTagManager.setUtm(utmParams);
    }
  }, [utmParams]);

  const theme = themeByCountryCode[countryCode];

  return (
    <NextIntlProvider locale={locale} messages={pageProps.messages}>
      <ThemeProvider theme={theme}>
        <CommonApiContext.Provider value={commonData}>
          <Box
            className={
              locale === Locale.siLK
                ? notoSansSinhala.className
                : milligram.className
            }
          >
            <RouteChangeProgress />

            {getLayout(<Component {...pageProps} />)}
          </Box>
        </CommonApiContext.Provider>
      </ThemeProvider>
    </NextIntlProvider>
  );
};

export default CeyloanApp;

CeyloanApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);
  const locale = context.router.locale || 'en';

  const commonData: ICommonApiData = {
    config: getConfig(),
    layoutSettings: null,
  };

  if (process.env.CMS_URL) {
    const commonApi = new CommonApi(locale);

    const layoutSettings = await commonApi.getLayoutSettings();

    commonData.layoutSettings = layoutSettings;
  }

  return {
    ...ctx,
    commonData,
  };
};
