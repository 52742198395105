import { ILayoutSettings } from '@/shared/api/common-api';
import { Config } from '@/shared/lib/config/types';

export enum ComponentType {
  Jumbotron = 'home.jumbotron',
  FirstLoanCalculator = 'home.first-loan-calculator',
  FirstLoanCalculator_V2 = 'home.first-loan-calculator-v2',
  PresentationBanner = 'shared.presentation-banner',
  PresentationBannerTambadana = 'shared.presentation-banner-tambadana',
  Partners = 'home.partners',
  Partners__shared = 'shared.partners',
  Steps = 'home.steps-section',
  Steps_V2 = 'home.steps-v2',
  Offers = 'home.offers',
  Requirements = 'home.requirements-section',
  Requirements_V2 = 'home.requrements-v2',
  LoanExample = 'home.loan-example',
  ExampleWithLink = 'home.loan-example-with-link',
  MobileAppBanner = 'home.mobile-app-section',
  MobileAppBanner__shared = 'shared.mobile-app-section',
  MobileAppBanner__shared_V2 = 'shared.mobile-app-section-v2',
  NumbersBlock = 'home.numbers-block',
  PayingMadeEasy = 'home.paying-made-easy',
  PopupBanner = 'shared.popup-banner',
  Testimonials = 'home.testimonials',
  FAQ = 'home.faq',
  AmmanaTargetOffer = 'home.ammana-target-offer',
  AmmanaTargetOffer_V2 = 'home.ammana-target-offer-v2',
  AmmanaStats = 'home.ammana-statistic',
  SimpleBanner = 'ab-testing.simple-banner',
  ABCalculator = 'ab-testing.calculator',
  CreditLimitSlider = 'ab-testing.credit-limit-slider',
  VideoFeedback = 'home.video-feedback',
}

export interface IPartner {
  id: number;
  attributes: {
    logo?: {
      data?: {
        attributes: {
          url: string;
          alt: string;
        };
      };
    };
  };
}

export interface IStyledTextCMS {
  fontSize: number;
  id: number;
  lineHeight: number;
  text: string;
}

export interface IBenefitItem {
  id: number;
  title: string;
  description: string;
  image: IImage;
}

export interface ISettings {
  google_play_badge_link: string;
  support_email: string;
}

export interface ImageAttributes {
  alternativeText?: string | null;
  url: string;
  width: number;
  height: number;
}

export interface IImage {
  data: {
    attributes: ImageAttributes;
  };
}

export interface ICommonApiData {
  config: Config;
  layoutSettings: ILayoutSettings | null;
}

/**
 * Country code according to {@link https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements ISO 3166}
 */
export const enum CountryCode {
  LK = 'LK',
  MY = 'MY',
  VN = 'VN',
  IN = 'IN',
  ID = 'ID',
  BD = 'BD',
}

export const enum Locale {
  siLK = 'si-LK',
  msMY = 'ms-MY',
  viVN = 'vi-VN',
  idID = 'id-ID',
  en = 'en',
  bnBD = 'bn-BD',
}

export type TPageData<T> = {
  id: number;
  attributes: {
    locale?: string;
    seo?: TSEOComponentData;
  } & T;
};

export type TSEOMetaSocialItem = {
  description: string;
  id: number;
  image?: IImage | null;
  socialNetwork: string;
  title: string;
};

export type TSEOComponentData = {
  id: number;
  canonicalURL?: string;
  keywords?: string;
  metaDescription: string;
  metaImage: IImage | null;
  metaRobots?: string;
  metaSocial?: TSEOMetaSocialItem[];
  metaTitle: string;
  metaViewport?: string;
  structuredData?: Record<string, any>;
};
