import { RepaymentRequisitesModalData } from '@/shared/api/repayment-api';
import { ActionSheet, Parser } from '@/shared/ui';
import { Box, Typography } from '@mui/material';
import React from 'react';

export const LoanAgreementNumberModal = ({
  isOpen,
  onClose,
  onOpen,
  modalData,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalData?: RepaymentRequisitesModalData;
}) => {
  if (!modalData) return null;

  return (
    <ActionSheet isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <Box p={3} pt={{ xs: 0.5, md: 3 }}>
        <Typography mb={3} mr={3} component="p" variant="h6" fontWeight="bold">
          {modalData.title}
        </Typography>
        <Parser data={modalData.content} />
      </Box>
    </ActionSheet>
  );
};
