import { Chip, ChipProps } from '@mui/material';
import React from 'react';

export interface CategoryItemProps extends ChipProps {
  isActive?: boolean;
  label: string;
  onDelete?: () => void;
  onSelect?: () => void;
}

export const CategoryItem = (props: CategoryItemProps) => {
  const { isActive, label, onDelete, onSelect } = props;

  const color = isActive ? 'primary' : 'default';

  const _onDelete = isActive ? onDelete : undefined;
  const _onSelect = isActive ? undefined : onSelect;

  return (
    <Chip
      {...props}
      color={color}
      label={label}
      onDelete={_onDelete}
      onClick={_onSelect}
    />
  );
};
