import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TypographyProps,
} from '@mui/material';

import { NotStyledParser } from '@/shared/ui';
import { FooterData } from '@/shared/api/common-api';
import { useConfig } from '@/shared/hooks';
import { AmmanaFooterLegal } from '@/shared/ui/footer/ammana-footer-legal';

type FooterBottomProps = Pick<
  FooterData,
  'requisites' | 'address' | 'allRightsReserved'
>;

export const FooterBottom = (props: FooterBottomProps) => {
  const { requisites, address } = props;
  const config = useConfig();

  const typographyProps: TypographyProps = {
    variant: 'text7',
    color: 'neutral.50',
    sx: {
      wordWrap: 'break-word',
    },
  };

  const allRightsReserved = (
    <Typography {...typographyProps}>
      © {new Date().getFullYear()} {config?.title}. {props.allRightsReserved}
    </Typography>
  );

  const requisitesNode = requisites ? (
    <Typography {...typographyProps}>
      <NotStyledParser data={requisites} />
    </Typography>
  ) : null;

  const addressNode = address ? (
    <Typography {...typographyProps}>
      <NotStyledParser data={address} />
    </Typography>
  ) : null;

  return (
    <Box
      sx={{
        bgcolor: 'neutral.10',
        color: 'neutral.50',
      }}
    >
      <Container
        sx={{
          py: { xs: 3.75, md: 2.5 },
          pb: {
            md: 4.25,
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              visibility: requisitesNode ? 'visible' : 'hidden',
            }}
          >
            {requisitesNode}
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              visibility: addressNode ? 'visible' : 'hidden',
            }}
          >
            {addressNode}
          </Grid>
          <Grid item xs={12} md={3}>
            {allRightsReserved}
          </Grid>
        </Grid>

        {config?.countryCode === 'ID' ? <AmmanaFooterLegal /> : null}
      </Container>
    </Box>
  );
};
