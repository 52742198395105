import { createTheme, ThemeOptions } from '@mui/material/styles';

import { core, CustomColor, gray, error } from './core';

const primary: CustomColor = {
  0: '#000000',
  10: '#002A1F',
  20: '#00402F',
  30: '#00553E',
  40: '#006A4E',
  50: '#1D886C',
  60: '#3BA689',
  70: '#58C3A7',
  80: '#67D2B6',
  90: '#76E1C4',
  95: '#84F0D3',
  99: '#93FFE2',
  100: '#ffffff',
};

const secondary: CustomColor = {
  0: '#000000',
  10: '#17493C',
  20: '#236D59',
  30: '#2E9277',
  40: '#3AB695',
  50: '#61C5AA',
  60: '#89D3BF',
  70: '#B0E2D5',
  80: '#C4E9DF',
  90: '#D8F0EA',
  95: '#EBF8F4',
  99: '#EDFAF6',
  100: '#ffffff',
};

const tertiary: CustomColor = {
  0: '#000000',
  10: '#62111A',
  20: '#921927',
  30: '#C32234',
  40: '#F42A41',
  50: '#F65567',
  60: '#F87F8D',
  70: '#FBAAB3',
  80: '#FCBFC6',
  90: '#FDD4D9',
  95: '#FEEAEC',
  99: '#FFF8F9',
  100: '#ffffff',
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      ...primary,
      contrastText: primary[100],
      dark: primary[30],
      light: primary[50],
      main: primary[40],
    },
    secondary: {
      ...secondary,
      contrastText: secondary[100],
      dark: secondary[30],
      light: secondary[50],
      main: secondary[40],
    },
    tertiary: {
      ...tertiary,
      contrastText: tertiary[100],
      dark: tertiary[30],
      light: tertiary[50],
      main: tertiary[40],
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },

    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      tertiary: tertiary[40],
      tertiaryContainer: tertiary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3:
        'linear-gradient(0deg, rgba(19, 153, 0, 0.04), rgba(19, 153, 0, 0.04)), #f4f4f4',
      surface4:
        'linear-gradient(0deg, rgba(19, 153, 0, 0.07), rgba(19, 153, 0, 0.07)), #f4f4f4',
      surface5:
        'linear-gradient(0deg, rgba(19, 153, 0, 0.1), rgba(19, 153, 0, 0.1)), #f4f4f4',
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
  },
};

export const dhardesh = createTheme(core, theme);
