import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { IconButton, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

export const ScrollToTopButton = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const matches: boolean = useMediaQuery('(min-width: 992px)');

  const toggleVisible = () => {
    setVisible(document.documentElement.scrollTop > 300);
  };

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', toggleVisible);
  }

  if (!visible || !matches) {
    return null;
  }

  return (
    <IconButton
      onClick={scrollToTop}
      sx={{ position: 'fixed', left: 20, top: 20, zIndex: 1 }}
    >
      <ArrowCircleUpIcon fontSize="large" />
    </IconButton>
  );
};
