import React from 'react';
import { Box, BoxProps } from '@mui/material';

type HorizontalSliderProps = {
  spacing: number;
  items: React.ReactElement[];
} & BoxProps;

export const HorizontalSlider = (props: HorizontalSliderProps) => {
  const { spacing, items, ...boxProps } = props;

  return (
    <Box
      mx={-spacing}
      sx={{
        overflow: 'auto',
        whiteSpace: 'nowrap',
        display: 'flex',
        flex: 'none',
        scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        outline: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '& *': {
          whiteSpace: 'initial',
        },
      }}
      tabIndex={0}
      {...boxProps}
    >
      {items?.map((item, index) => (
        <Box
          key={index}
          ml={spacing}
          mr={index === items.length - 1 ? spacing : 0}
          sx={{
            display: 'inline-block',
            scrollSnapAlign: 'center',
          }}
        >
          {item}
        </Box>
      ))}
    </Box>
  );
};
