import React from 'react';
import { Avatar, Box, Card, Stack, Rating } from '@mui/material';
import { TestimonialItem } from '@/shared/api/homepage-api';
import { ResponsiveTypography } from '@/shared/ui';

export const FeedbackCard = (props: TestimonialItem) => {
  const { rating, text, customerName, customerPhoto } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        p: 3.75,
        '& > *': {
          userSelect: 'none',
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={3}>
        <Avatar
          alt={customerName}
          src={customerPhoto?.data?.attributes?.url}
          sx={{ width: { xs: 75, md: 90 }, height: { xs: 75, md: 90 } }}
        />
        <ResponsiveTypography
          variantMap={{
            xs: 'headline7',
            md: 'headline5',
          }}
          color="neutral.20"
          sx={{
            wordBreak: 'break-word',
          }}
        >
          {customerName}
        </ResponsiveTypography>
      </Stack>

      <Box my={3}>
        <Rating precision={0.5} value={rating} readOnly />
      </Box>

      <ResponsiveTypography
        variantMap={{
          xs: 'text6',
          md: 'text4',
        }}
        color="neutral.50"
      >
        {text}
      </ResponsiveTypography>
    </Card>
  );
};
