import NextLink from 'next/link';
import {
  Box,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';

export type GuideItemProps = {
  id: number;
  title?: string;
  category?: string;
  image?: { url?: string; alt?: string };
  isActive?: boolean;
};

export const GuideItem = ({
  id,
  title,
  category,
  image,
  isActive = false,
}: GuideItemProps) => {
  return (
    <NextLink href={{ pathname: `/repayment/guides/${id}` }}>
      <ListItemButton
        selected={isActive}
        divider={true}
        sx={{
          mx: {
            md: -3,
          },
          px: {
            xs: 0,
            md: 2.5,
          },
          '&:hover': {
            bgcolor: 'neutral.95',
          },
          '&.Mui-selected': {
            bgcolor: 'primary.95',
          },
        }}
      >
        <ListItemAvatar>
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: 50,
              bgcolor: 'neutral.100',
              backgroundImage: `url(${image?.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="headline8" color="neutral.20" component="div">
              {title}
            </Typography>
          }
          secondary={
            <Typography variant="text7" color="neutral.50">
              {category}
            </Typography>
          }
        />
      </ListItemButton>
    </NextLink>
  );
};
