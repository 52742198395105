import React from 'react';
import { Button, ButtonProps, Typography } from '@mui/material';
import { IconClose, IconMenu } from '@/shared/ui';

interface BurgerMenuBtnProps {
  isActive: boolean;
  onClick: ButtonProps['onClick'];
  children: ButtonProps['children'];
}

export const BurgerMenuBtn = (props: BurgerMenuBtnProps) => {
  const { isActive, onClick, children } = props;

  return (
    <Button
      color={isActive ? 'secondary' : 'primary'}
      size="small"
      onClick={onClick}
      endIcon={isActive ? <IconClose /> : <IconMenu />}
      sx={{
        minWidth: {
          xs: 38,
          md: 'auto',
        },
        px: 2,
        '& .MuiButton-endIcon': {
          ml: {
            xs: 0,
            md: children ? 1 : 0,
          },
        },
      }}
    >
      <Typography
        variant="headline9"
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        {children}
      </Typography>
    </Button>
  );
};
