import { Link } from '@mui/material';
import * as React from 'react';

import { Image } from './image';

export type GooglePlayBadgeProps = {
  href: string;
  width?: number;
};

export const GooglePlayBadge = ({
  href,
  width = 184,
}: GooglePlayBadgeProps) => {
  return (
    <Link href={href} target="_blank" display="block" width={width}>
      <Image />
    </Link>
  );
};
