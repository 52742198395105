import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconMenu = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M4.25 6H14.75C14.9489 6 15.1397 5.92098 15.2803 5.78033C15.421 5.63968 15.5 5.44891 15.5 5.25C15.5 5.05109 15.421 4.86032 15.2803 4.71967C15.1397 4.57902 14.9489 4.5 14.75 4.5H4.25C4.05109 4.5 3.86032 4.57902 3.71967 4.71967C3.57902 4.86032 3.5 5.05109 3.5 5.25C3.5 5.44891 3.57902 5.63968 3.71967 5.78033C3.86032 5.92098 4.05109 6 4.25 6ZM16.25 8.25H2.75C2.55109 8.25 2.36032 8.32902 2.21967 8.46967C2.07902 8.61032 2 8.80109 2 9C2 9.19891 2.07902 9.38968 2.21967 9.53033C2.36032 9.67098 2.55109 9.75 2.75 9.75H16.25C16.4489 9.75 16.6397 9.67098 16.7803 9.53033C16.921 9.38968 17 9.19891 17 9C17 8.80109 16.921 8.61032 16.7803 8.46967C16.6397 8.32902 16.4489 8.25 16.25 8.25ZM14.75 12H4.25C4.05109 12 3.86032 12.079 3.71967 12.2197C3.57902 12.3603 3.5 12.5511 3.5 12.75C3.5 12.9489 3.57902 13.1397 3.71967 13.2803C3.86032 13.421 4.05109 13.5 4.25 13.5H14.75C14.9489 13.5 15.1397 13.421 15.2803 13.2803C15.421 13.1397 15.5 12.9489 15.5 12.75C15.5 12.5511 15.421 12.3603 15.2803 12.2197C15.1397 12.079 14.9489 12 14.75 12Z"
      />
    </SvgIcon>
  );
};
