import { IPaymentProvider } from '@/shared/api/repayment-guide-api';
import { useTranslations } from 'use-intl';
import {
  FormControl,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Image from 'next/image';
import React from 'react';

export type ProviderSelectProps = {
  onChange: (value: number) => void;
  options?: IPaymentProvider[];
  value: number;
};

export const ProviderSelect = ({
  onChange,
  options = [],
  value,
}: ProviderSelectProps) => {
  const t = useTranslations();

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    onChange(e.target.value as number);
  };

  return (
    <FormControl>
      <Select
        fullWidth
        onChange={handleChange}
        value={value}
        renderValue={(value) => {
          const option = options.find((item) => item.id === value);

          if (!option) {
            return t('repayment.filterProviderOptionAll');
          }

          return option.attributes.name;
        }}
      >
        <MenuItem value={0}>{t('repayment.filterProviderOptionAll')}</MenuItem>
        {options.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <ListItemAvatar>
                <Image
                  src={item.attributes.logo?.data?.attributes.url + ''}
                  alt={item.attributes.logo?.data?.attributes.alt + ''}
                  width={40}
                  height={40}
                  style={{ objectFit: 'contain' }}
                />
              </ListItemAvatar>
              <ListItemText primary={item.attributes.name} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
