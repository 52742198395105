import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import NextLink from 'next/link';
import Image from 'next/image';
import { FooterData } from '@/shared/api/common-api';
import { Logo } from '@/shared/ui';
import { NavLink } from '@/shared/lib';
import { redirectToAccount } from '@/utils';

import { FooterContacts } from './footer-contacts';
import { FooterNavMenu } from './nav-menu';

type FooterTopProps = {
  links: NavLink[];
} & Pick<
  FooterData,
  | 'socialTitle'
  | 'socialLinks'
  | 'googlePlayLink'
  | 'contactsTitle'
  | 'footerContact'
  | 'loginButton'
  | 'showAccountBtn'
>;

export const FooterTop = (props: FooterTopProps) => {
  const {
    socialTitle,
    socialLinks,
    googlePlayLink,
    links,
    footerContact,
    contactsTitle,
    loginButton,
    showAccountBtn,
  } = props;

  const socialNetworks = (
    <Stack
      spacing={1.25}
      mt={{
        xs: 2,
        md: 1.5,
      }}
      direction="row"
      alignItems="flex-end"
    >
      {socialTitle ? (
        <Typography variant="text6">{socialTitle}</Typography>
      ) : null}
      <Stack spacing={1} direction="row" alignItems="center">
        {socialLinks?.map((item) => (
          <Box
            key={item.id}
            component={Link}
            href={item.link}
            sx={{ display: 'flex', width: 28, height: 28 }}
          >
            <Image
              src={item.icon.data.attributes.url}
              alt=""
              width={30}
              height={31}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );

  const googlePlayBadge = googlePlayLink ? (
    <Button
      component={Link}
      href={googlePlayLink}
      target="_blank"
      variant="outlined"
      color="secondary"
      sx={{
        flex: { xs: 1, md: 0 },
        p: 1.625,
        borderColor: 'neutral.50',
        minWidth: {
          md: 160,
        },
      }}
    >
      Google Play
    </Button>
  ) : null;

  const divider = (
    <Divider
      sx={{
        mx: {
          xs: -2,
          md: 0,
        },
        bgcolor: 'neutral.20',
      }}
    />
  );

  const contactsTitleNode = contactsTitle ? (
    <>
      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        <Typography variant="text6" sx={{ mb: 2 }}>
          {contactsTitle}
        </Typography>
      </Box>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        <Typography variant="headline8" sx={{ mb: 2 }}>
          {contactsTitle}
        </Typography>
      </Box>
    </>
  ) : null;

  const logo = (
    <>
      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        <Logo width={130} variant="light" />
      </Box>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        <Logo width={93} variant="light" />
      </Box>
    </>
  );

  return (
    <Box
      sx={{
        bgcolor: 'neutral.0',
        color: 'common.white',
      }}
    >
      <Container sx={{ py: { xs: 3, md: 5 } }}>
        <Grid
          container
          spacing={{
            xs: 4,
            md: 3,
          }}
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            <Link href="/" component={NextLink}>
              {logo}
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            display={{
              xs: 'block',
              md: 'none',
            }}
          >
            {divider}
          </Grid>

          <Grid item xs={12} md={6} order={{ xs: 7, md: 0 }}>
            <Stack
              spacing={1.25}
              mb={{
                xs: 4.5,
                md: 0,
              }}
              direction="row"
              justifyContent={{
                xs: 'space-between',
                md: 'flex-end',
              }}
            >
              {showAccountBtn ? (
                <Button
                  onClick={() => {
                    redirectToAccount();
                  }}
                  sx={{
                    flex: { xs: 1, md: 0 },
                    bgcolor: 'neutral.100',
                    p: 1.625,
                    minWidth: {
                      md: 160,
                    },
                    '&:hover > *': {
                      color: 'neutral.100',
                    },
                  }}
                >
                  <Typography variant="headline8" color="neutral.20">
                    {loginButton}
                  </Typography>
                </Button>
              ) : null}
              {googlePlayBadge}
            </Stack>
          </Grid>
          <Grid item xs={12} order={{ xs: 3, md: 0 }}>
            {divider}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 4, md: 0 }}
            sx={{
              visibility:
                contactsTitleNode || footerContact ? 'visible' : 'hidden',
            }}
          >
            {contactsTitleNode}
            <FooterContacts footerContact={footerContact} />
            {socialNetworks}
          </Grid>
          <Grid
            item
            xs={12}
            order={{ xs: 4, md: 0 }}
            display={{
              xs: 'block',
              md: 'none',
            }}
          >
            {divider}
          </Grid>
          <Grid item xs={12} md={6} alignSelf="flex-start">
            <FooterNavMenu links={links} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
