import React from 'react';
import {
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemText,
  SvgIconProps,
  Typography,
} from '@mui/material';

interface ListItemProps {
  primaryText: string | React.ReactElement;
  secondaryText: string | React.ReactElement;
  IconComponent?: React.ReactElement<SvgIconProps>;
}

export const ListItem = (props: ListItemProps) => {
  const { primaryText, secondaryText, IconComponent } = props;

  const getListItemTextTypographyProps = (
    primaryText: string | React.ReactElement,
    secondaryText: string | React.ReactElement,
  ) => {
    return {
      primary: (
        <Typography
          variant="headline8"
          color="neutral.50"
          component="div"
          mb={0.625}
        >
          {primaryText}
        </Typography>
      ),
      secondary: (
        <Typography variant="text6" color="neutral.50">
          {secondaryText}
        </Typography>
      ),
    };
  };

  return (
    <MuiListItem
      alignItems="flex-start"
      sx={{
        px: 0,
      }}
    >
      {IconComponent ? (
        <ListItemAvatar
          sx={{
            minWidth: 24,
            marginRight: 1.5,
          }}
        >
          {IconComponent}
        </ListItemAvatar>
      ) : null}

      <ListItemText
        {...getListItemTextTypographyProps(primaryText, secondaryText)}
      />
    </MuiListItem>
  );
};
