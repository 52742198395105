import React from 'react';
import { Grid, Paper } from '@mui/material';

export const PageSidebar = ({
  children,
}: {
  children?: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Grid item xs={12} lg={3}>
      <Paper
        sx={{
          px: {
            md: 3,
          },
          pt: {
            md: 4,
          },
          pb: 2,
          bgcolor: 'common.white',
          borderColor: 'neutral.90',
          borderWidth: {
            xs: 0,
            md: 1,
          },
          borderStyle: 'solid',
          borderRadius: {
            xs: 0,
            md: 2.5,
          },
          overflow: {
            md: 'hidden',
          },
        }}
      >
        {children}
      </Paper>
    </Grid>
  );
};
