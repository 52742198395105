import React from 'react';
import { Box } from '@mui/material';
import ellipseBackground from '@/assets/images/ellipse-circle-photo.svg';
import { IImage } from '@/shared/types';

interface RoundedImageProps {
  size: number;
  image: IImage;
}

export const RoundedImage = (props: RoundedImageProps) => {
  const { size, image } = props;

  const IMAGE_MARGINS_PERCENT = 10;

  const imageSize = (size * (100 - IMAGE_MARGINS_PERCENT)) / 100;

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      sx={{
        display: 'flex',
        width: size,
        height: size,
        background: `url(${ellipseBackground.src}) no-repeat bottom center`,
        backgroundSize: 'contain',
      }}
    >
      <Box
        sx={{
          width: imageSize,
          height: imageSize,
          borderRadius: '50%',
          overflow: 'hidden',
          background: `url(${image?.data?.attributes?.url}) no-repeat center center`,
          backgroundSize: 'cover',
        }}
      />
    </Box>
  );
};
