import React from 'react';
import { Box, Container, Paper, SxProps, Theme, useTheme } from '@mui/material';

interface SectionOuterProps {
  children: React.ReactNode | React.ReactNode[];
  color?: string;
  sx?: SxProps<Theme>;
  disableGutters?: boolean;
  disablePaddings?: boolean;
  fullHeight?: boolean;
  id?: string;
}

export const SectionOuter = (props: SectionOuterProps) => {
  const {
    children,
    sx,
    color,
    disableGutters,
    disablePaddings,
    fullHeight,
    id,
  } = props;

  const theme = useTheme();

  return (
    <Container
      disableGutters={disableGutters}
      id={id}
      sx={{
        height: fullHeight ? '100%' : 'initial',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          overflow: 'hidden',
          height: fullHeight ? '100%' : 'initial',
          bgcolor: color,
          background: color,
          marginBottom: {
            xs: 3,
            md: 6,
            lg: 8,
          },
          [theme.breakpoints.up('md')]: {
            boxShadow: 'none',
          },
          ...sx,
        }}
      >
        <Box
          p={disablePaddings ? 0 : { xs: 2, sm: 4 }}
          sx={{
            height: fullHeight ? '100%' : 'initial',
          }}
        >
          {children}
        </Box>
      </Paper>
    </Container>
  );
};
