import React from 'react';
import { useTranslations } from 'use-intl';
import { useConfig, useCountryCode } from '@/shared/hooks';
import { Alert, AlertTitle, Link, Stack, Typography } from '@mui/material';
import { ContactChip } from './contact-chip';
import { useCommonApi } from '@/shared/contexts';
import { CountryCode } from '@/shared/types';

export const WarningPostPayment = () => {
  const t = useTranslations();

  const config = useConfig();

  const commonApiContext = useCommonApi();
  const repaymentSettings =
    commonApiContext?.layoutSettings?.attributes?.repayment;

  const countryCode = useCountryCode();

  const phoneNumberChip = repaymentSettings?.phoneNumber ? (
    <ContactChip>
      {t('repayment.warningPostPaymentPhoneNumber', {
        phoneNumber:
          commonApiContext?.layoutSettings?.attributes?.repayment?.phoneNumber,
      })}
    </ContactChip>
  ) : null;

  const whattsAppChip = repaymentSettings?.whatsApp ? (
    <ContactChip>
      {t.rich('repayment.warningPostPaymentMessengers', {
        whatsapp: (chunks) => {
          return (
            <Link
              href={
                commonApiContext?.layoutSettings?.attributes?.repayment
                  ?.whatsApp
              }
              target="_blank"
              color="inherit"
              fontWeight="bold"
              sx={{
                transition: 'none',
              }}
            >
              {chunks}
            </Link>
          );
        },
      })}
    </ContactChip>
  ) : null;

  return (
    <Alert
      severity="warning"
      variant="outlined"
      sx={{
        position: 'relative',
        border: 'none',
        p: 0,
        '.MuiAlert-icon': {
          position: 'absolute',
          padding: '10px 0',
          color: 'primary.40',
          fontSize: 24,
        },

        '.MuiAlert-message': {
          fontSize: 16,
          color: '#111614',
        },
      }}
    >
      <AlertTitle
        sx={{
          ml: 4,
          mb: 2.5,
        }}
      >
        <Typography variant="headline8" color="primary.40">
          {t('repayment.warningPostPaymentTitle')}
        </Typography>
        <Typography variant="text6" color="neutral.50" component="div">
          {t('repayment.warningPostPaymentContent')}
        </Typography>
      </AlertTitle>
      <Stack direction="row" flexWrap="wrap">
        {countryCode === CountryCode.LK ? (
          [phoneNumberChip, whattsAppChip]
        ) : (
          <ContactChip>
            {t.rich('repayment.warningPostPaymentEmail', {
              email: () => {
                return (
                  <Link
                    href={`mailto:${config?.externalLinks.payEmail}`}
                    fontWeight="bold"
                  >
                    {config?.externalLinks.payEmail}
                  </Link>
                );
              },
            })}
          </ContactChip>
        )}
      </Stack>
    </Alert>
  );
};
