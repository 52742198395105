import React from 'react';
import NextLink from 'next/link';
import { Box, Link } from '@mui/material';
import { NavLink } from '@/shared/lib/navigations';
import { ResponsiveTypography } from '@/shared/ui';

export type HeaderNavMenuProps = {
  links?: NavLink[];
  onClick: () => void;
};

export const HeaderNavMenu = ({ links, onClick }: HeaderNavMenuProps) => {
  if (!links) {
    console.error('Header nav items does not exists');
    return null;
  }

  return (
    <>
      {links.map(({ href, text, target }: NavLink, i) => (
        <Box
          key={i}
          mt={{
            xs: 0,
            md: 4,
          }}
          mb={{
            xs: 3.5,
            md: 0,
          }}
        >
          <Link
            underline="none"
            component={NextLink}
            href={href}
            target={target}
            onClick={onClick}
            color="neutral.100"
            sx={{
              '&:hover': {
                color: 'neutral.20',
              },
            }}
          >
            <ResponsiveTypography
              variantMap={{ xs: 'headline5', md: 'headline2' }}
            >
              {text}
            </ResponsiveTypography>
          </Link>
        </Box>
      ))}
    </>
  );
};
