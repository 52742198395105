import { Box } from '@mui/material';
import React from 'react';

interface ContactChipProps {
  children: React.ReactNode | React.ReactNode[];
}

export const ContactChip = (props: ContactChipProps) => {
  return (
    <Box
      mb={1.25}
      mr={1.25}
      sx={{
        borderRadius: 1.5,
        borderWidth: 1,
        borderColor: 'neutral.90',
        borderStyle: 'solid',
        py: 1,
        px: 2,
        transition: 'all .25s ease',
        '&:hover': {
          bgcolor: 'neutral.20',
          color: 'neutral.100',
        },
      }}
    >
      {props.children}
    </Box>
  );
};
