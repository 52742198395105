import * as React from 'react';

import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

export type InputProps = TextFieldProps;

export const Input = React.forwardRef<HTMLDivElement, InputProps>(
  (props, ref) => {
    return (
      <MuiTextField InputLabelProps={{ shrink: true }} {...props} ref={ref} />
    );
  },
);

Input.displayName = 'Input';
