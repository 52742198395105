import React from 'react';
import {
  Box,
  Grid,
  Link,
  SwipeableDrawer,
  Container,
  Stack,
} from '@mui/material';
import { NavLink } from '@/shared/lib';
import { ILayoutSettings } from '@/shared/api/common-api';

import { HeaderNavMenu } from './nav-menu';
import { ContactLink } from './contact-link';

type SwipeableNavProps = {
  links: NavLink[];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
} & Pick<
  ILayoutSettings['attributes'],
  'socialNetworks' | 'supportEmail' | 'supportPhoneNumber'
>;

export const SwipeableNav = (props: SwipeableNavProps) => {
  const {
    links,
    supportPhoneNumber,
    supportEmail,
    socialNetworks,
    isOpen,
    onOpen,
    onClose,
  } = props;

  return (
    <SwipeableDrawer
      anchor="right"
      PaperProps={{
        square: true,
        sx: {
          width: '100%',
          bgcolor: 'primary.40',
          display: 'flex',
          justifyContent: {
            xs: 'start',
            md: 'center',
          },
          pt: {
            xs: 8.75,
            md: 0,
          },
        },
      }}
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Container
        sx={{
          pt: {
            xs: 3.75,
            md: 0,
          },
          height: {
            xs: '100%',
            md: 'auto',
          },
          overflowY: {
            xs: 'auto',
            md: 'hidden',
          },
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid
            item
            xs={12}
            md
            display="flex"
            flexDirection="column"
            sx={{
              justifyContent: 'flex-end',
              textAlign: {
                xs: 'center',
                md: 'left',
              },
            }}
          >
            {supportEmail ? (
              <ContactLink href={`mailto:${supportEmail}`}>
                {supportEmail}
              </ContactLink>
            ) : null}
            {supportPhoneNumber ? (
              <ContactLink href={`tel:${supportPhoneNumber}`}>
                {supportPhoneNumber}
              </ContactLink>
            ) : null}

            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              display={socialNetworks?.length ? 'flex' : 'none'}
              justifyContent={{
                xs: 'center',
                md: 'flex-start',
              }}
              mt={{
                md: 5.5,
              }}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  borderTop: `1px solid ${theme.palette.primary[50]}`,
                  borderBottom: `1px solid ${theme.palette.primary[50]}`,
                  mx: -2,
                  p: 3,
                },
              })}
            >
              {socialNetworks?.map((item) => (
                <Box
                  key={item.id}
                  component={Link}
                  href={item.link}
                  target="_blank"
                  sx={{
                    display: 'flex',
                    width: 30,
                    height: 30,
                    '&:hover': {
                      filter: 'brightness(0.1)',
                    },
                  }}
                >
                  <img src={item?.icon?.data?.attributes.url} alt="" />
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            order={{
              xs: -1,
              md: 0,
            }}
          >
            <HeaderNavMenu onClick={onClose} links={links} />
          </Grid>
        </Grid>
      </Container>
    </SwipeableDrawer>
  );
};
