import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import React from 'react';

export const LoanAgreementGuideItem = ({
  imageSrc,
  steps,
  title,
}: {
  imageSrc?: string;
  steps: string[];
  title: string;
}) => (
  <Box maxWidth={520}>
    <Typography mb={2} component="p" variant="headline6" color="neutral.50">
      {title}
    </Typography>
    <Box mb={2}>
      {imageSrc ? (
        <Image
          style={{
            border: '1px solid #eeeeee',
            borderRadius: 4,
            width: '100%',
            maxWidth: 320,
          }}
          width={320}
          height={380}
          src={imageSrc}
          alt="Loan agreement number"
        />
      ) : null}
    </Box>
    <Stack spacing={1}>
      {steps.map((step, i) => {
        return (
          <Typography key={i} variant="text5">
            {step}
          </Typography>
        );
      })}
    </Stack>
  </Box>
);
