import React from 'react';
import { useTranslations } from 'use-intl';
import { useConfig } from '@/shared/hooks';
import { Box, Divider, List, Stack, Typography, useTheme } from '@mui/material';
import {
  IconCreditCard,
  IconDialpadAlt,
  IconInfoCircle,
  IconReceipt,
  IconUserCircle,
} from '@/shared/ui';
import HelpIcon from '@mui/icons-material/Help';
import { ListItem } from './list-item';

interface RepaymentRequisitesProps {
  accountNumber?: string;
  bankAccountHolderName: string;
  branchName?: string;
  loanAgreementNumber: string;
  onHelpIconClick: () => void;
}

export const RepaymentRequisites = ({
  accountNumber,
  bankAccountHolderName,
  branchName,
  loanAgreementNumber,
  onHelpIconClick,
}: RepaymentRequisitesProps) => {
  const t = useTranslations();
  const config = useConfig();
  const theme = useTheme();

  return (
    <Box>
      <List sx={{ p: 0 }}>
        <ListItem
          primaryText={t('repayment.requisitesBankAccountHolderName')}
          secondaryText={bankAccountHolderName}
          IconComponent={
            <IconUserCircle htmlColor={theme.palette.neutral[50]} />
          }
        />

        <Divider />
        {branchName ? (
          <React.Fragment>
            <ListItem
              primaryText={t('repayment.requisitesBankAccountBranchName')}
              secondaryText={branchName}
              IconComponent={
                <IconCreditCard htmlColor={theme.palette.neutral[50]} />
              }
            />

            <Divider />
          </React.Fragment>
        ) : null}
        {accountNumber ? (
          <React.Fragment>
            <ListItem
              primaryText={t(
                config?.countryCode === 'MY'
                  ? 'repayment.requisitesBankBusinessRegistrationNumber'
                  : 'repayment.requisitesBankAccountNumber',
              )}
              secondaryText={accountNumber}
              IconComponent={
                <IconDialpadAlt htmlColor={theme.palette.neutral[50]} />
              }
            />

            <Divider />
          </React.Fragment>
        ) : null}
        <ListItem
          primaryText={t('repayment.requisitesLoanAgreementNumberLabel')}
          secondaryText={
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              component="span"
            >
              <Typography component="span">{loanAgreementNumber}</Typography>
              <IconInfoCircle
                onClick={onHelpIconClick}
                sx={{
                  p: 0,
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'neutral.20',
                  },
                }}
              >
                <HelpIcon />
              </IconInfoCircle>
            </Stack>
          }
          IconComponent={<IconReceipt htmlColor={theme.palette.neutral[50]} />}
        />
      </List>
    </Box>
  );
};
