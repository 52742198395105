import * as React from 'react';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import Image from 'next/image';
import { IImage } from '@/shared/types';

import { GooglePlayBadge } from '../google-play-badge';

export type MobileAppBannerFeature = {
  text: string;
  id: number;
  image: IImage;
};

export type MobileAppBannerOrientation = 'vertical' | 'horizontal';

export type MobileAppBannerProps = {
  features: MobileAppBannerFeature[];
  imageAlt: string;
  imageUrl: string;
  orientation: MobileAppBannerOrientation;
  title?: string;
  googlePlayAlt?: string;
  googlePlayLink?: string;
  sx?: SxProps;
};

export const MobileAppBanner = ({
  features = [],
  googlePlayLink,
  orientation,
  imageAlt,
  imageUrl,
  title,
  sx,
}: MobileAppBannerProps) => {
  const isVertical = orientation === 'vertical';

  return (
    <Stack
      p={isVertical ? 0 : 4}
      direction={isVertical ? 'column' : 'row'}
      justifyContent="space-between"
      borderRadius="inherit"
      sx={sx}
    >
      <Box
        height={isVertical ? 260 : 320}
        pt={2}
        px={2}
        order={isVertical ? 0 : 2}
        sx={{
          width: isVertical ? 'auto' : 560,
          borderTopRightRadius: 'inherit',
          borderBottomRightRadius: isVertical ? 0 : 'inherit',
          borderBottomLeftRadius: isVertical ? 0 : 'inherit',
          borderTopLeftRadius: 'inherit',
          backgroundColor: 'common.primaryContainer',
        }}
      >
        <Box
          component="img"
          src={imageUrl}
          alt={imageAlt}
          width="100%"
          height="100%"
          style={{ objectFit: 'contain', objectPosition: 'center bottom' }}
        />
      </Box>
      <Stack
        p={isVertical ? 2 : 0}
        mr={isVertical ? 2 : 4}
        justifyContent="space-between"
      >
        <Box>
          <Typography mb={isVertical ? 2 : 3} variant="headline6">
            {title}
          </Typography>
          <Stack mb={3} spacing={2}>
            {features.map((feature, i) => {
              return (
                <Stack
                  key={i}
                  spacing={1.5}
                  direction="row"
                  alignItems="center"
                >
                  <Image
                    src={feature.image?.data?.attributes?.url}
                    alt={feature.image?.data?.attributes?.alternativeText || ''}
                    width={24}
                    height={24}
                  />
                  <Typography variant="text7">{feature.text}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </Box>
        {googlePlayLink ? (
          <Stack
            flexDirection="row"
            justifyContent={isVertical ? 'center' : 'flex-start'}
          >
            <GooglePlayBadge href={googlePlayLink} />
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};
