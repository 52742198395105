export const LOAN_CALCULATOR_ITEM_NODE_ID = 'first-loan-calculator-item';
export const LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID =
  'first-loan-calculator-phone-input-outer';

const getCalculatorNode = () => {
  return document && document.getElementById(LOAN_CALCULATOR_ITEM_NODE_ID);
};

export const getPhoneInputParentElement = () => {
  const phoneInputElement =
    document &&
    document.getElementById(LOAN_CALCULATOR_PHONE_INPUT_PARENT_NODE_ID);
  if (phoneInputElement) {
    return phoneInputElement.parentElement;
  }
  return null;
};

export const scrollToCalculatorNode = () => {
  const calculatorNode = getCalculatorNode();
  if (calculatorNode) {
    calculatorNode.scrollIntoView();
    const phoneInputElementParent = getPhoneInputParentElement();
    if (phoneInputElementParent) {
      phoneInputElementParent.style.boxShadow = '0 0 0 2px #ba1a25';
    }
  }
};

export const checkFirstLoanCalculator = () => {
  return !!getCalculatorNode();
};
