import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconChatbot = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10,18 L6,22 L6,18 L10,18 Z M17,6 C19.7614237,6 22,8.23857625 22,11 C22,13.7614237 19.7614237,16 17,16 L17,16 L7,16 C4.23857625,16 2,13.7614237 2,11 C2,8.23857625 4.23857625,6 7,6 L7,6 Z"
        transform="translate(12.000000, 14.000000) scale(-1, 1) translate(-12.000000, -14.000000) "
      ></path>
    </SvgIcon>
  );
};
