import React from 'react';
import { keyframes } from '@emotion/react';
import { Button, ButtonProps } from '@mui/material';

type GradientButtonProps = ButtonProps & {
  animate?: boolean;
};

export const GradientButton = (props: GradientButtonProps) => {
  const { animate } = props;

  const gradientAnimation = keyframes`
    0% {
      left: -100%
    }
    50% {
      left: 0
    }
    100% {
      left: 100%
    }
  `;

  const buttonGradientAnimationStyles = {
    position: 'relative',
    overflow: 'hidden',
    border: 0,
    zIndex: 0,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '-100%',
      width: '100%',
      height: '100%',
      background:
        'linear-gradient(50deg, transparent 10%, rgba(255,255,255,0.35) 50%, transparent 90%)',
      backgroundSize: '100% auto',
      animation: `${gradientAnimation} 3s linear infinite`,
      zIndex: -1,
    },
  };

  let buttonStyles = {
    ...props.sx,
  };

  if (animate) {
    buttonStyles = { ...buttonStyles, ...buttonGradientAnimationStyles };
  }

  const buttonProps = { ...props };
  delete buttonProps.animate;

  return <Button {...buttonProps} sx={buttonStyles} />;
};
