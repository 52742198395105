import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const IconArrowDropdown = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.71054 11.71L11.3005 14.3C11.6905 14.69 12.3205 14.69 12.7105 14.3L15.3005 11.71C15.9305 11.08 15.4805 10 14.5905 10H9.41054C8.52054 10 8.08054 11.08 8.71054 11.71Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
