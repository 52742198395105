import {
  checkFirstLoanCalculator,
  scrollToCalculatorNode,
} from '@/utils/loan-calculator';

export const isTambadanaMirror = () => {
  if (typeof window !== 'undefined') {
    const host = window.location.host;

    return host === 'tambadana.my' || host === 'tambadana.com';
  }
};

export const redirectTambadanaWhattsapp = () => {
  window.location.href =
    'https://wa.me/601110810022?text=Hi,%20saya%20ingin%20mengetahui%20dengan%20lebih%20lanjut%20mengenai%20pinjaman%20peribadi%20Tambadana';
};

export const redirectToAccount = (params = '') => {
  const url = new URL('/account' + params, window.location.origin);

  window.location.href = url.toString();
};

export const handleActionButtonClick = () => {
  if (checkFirstLoanCalculator()) {
    scrollToCalculatorNode();
  } else {
    redirectToAccount();
  }
};
