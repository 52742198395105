import React, { useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useConfig } from '@/shared/hooks/use-config';
import { Locale } from '@/shared/types';
import { IconArrowDropdown } from '@/shared/ui/icons';
import { GB, ID, LK, MY, VN, BD } from 'country-flag-icons/react/3x2';

const flagProps = {
  width: 19,
};

const localesMap = [
  {
    label: 'English',
    shortLabel: 'En',
    lang: Locale.en,
    icon: <GB {...flagProps} />,
  },
  {
    label: 'සිංහල',
    shortLabel: 'Si',
    lang: Locale.siLK,
    icon: <LK {...flagProps} />,
  },
  {
    label: 'Malay',
    shortLabel: 'BM',
    lang: Locale.msMY,
    icon: <MY {...flagProps} />,
  },
  {
    label: 'Vietnamese',
    shortLabel: 'Vi',
    lang: Locale.viVN,
    icon: <VN {...flagProps} />,
  },
  {
    label: 'Indonesian',
    shortLabel: 'Id',
    lang: Locale.idID,
    icon: <ID {...flagProps} />,
  },
  {
    label: 'Bengali',
    shortLabel: 'BD',
    lang: Locale.bnBD,
    icon: <BD {...flagProps} />,
  },
];

type LocaleSwitcherProps = {
  isActive: boolean;
};

export const LocaleSwitcher = (props: LocaleSwitcherProps) => {
  const { isActive } = props;
  const { locale, pathname, query } = useRouter();

  const appConfig = useConfig();

  const [menuAnchorEl, setMenuAnchorEl] = useState<
    null | Element | ((element: Element) => Element)
  >(null);
  const handleMenuOpen = (event: React.MouseEvent) =>
    setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const handleMenuSwitch = (event: React.MouseEvent) => {
    menuAnchorEl ? handleMenuClose() : handleMenuOpen(event);
  };

  const langs = localesMap.filter((item) =>
    appConfig?.locales.includes(item.lang),
  );

  const currentLocale = langs.find(({ lang }) => {
    return lang === locale;
  });

  const switcherButton = (
    <Button
      color={isActive ? 'secondary' : 'primary'}
      onClick={handleMenuSwitch}
      size="small"
      variant="outlined"
      endIcon={<IconArrowDropdown />}
      aria-controls={menuAnchorEl ? 'lang-switcher-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={menuAnchorEl ? 'true' : undefined}
      sx={{
        minWidth: 0,
        px: 1.5,
        py: {
          xs: 1.125,
          md: 1.25,
        },
        '& .MuiButton-endIcon': {
          ml: 0,
        },
      }}
    >
      {currentLocale?.icon}
      <Typography
        variant="headline9"
        textTransform="capitalize"
        sx={{
          ml: 1,
        }}
        display={{
          xs: 'none',
          md: 'block',
        }}
      >
        {currentLocale?.shortLabel}
      </Typography>
    </Button>
  );

  if (langs?.length < 2) {
    return null;
  }

  return (
    <Box>
      {switcherButton}
      <Menu
        id="lang-switcher-menu"
        anchorEl={menuAnchorEl as any}
        onClose={handleMenuClose}
        open={!!menuAnchorEl}
      >
        {langs.map(({ label, lang, icon }) => (
          <MenuItem
            component={NextLink}
            href={{ pathname, query }}
            key={lang}
            locale={lang}
            dense
            onClick={handleMenuClose}
            selected={locale === lang}
            sx={{
              width: { xs: 'auto' },
              px: { xs: 1, md: 2 },
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              textTransform="capitalize"
            >
              {icon}
              <Typography variant="text6">{label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
