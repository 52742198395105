import { createTheme, ThemeOptions } from '@mui/material/styles';

import { core, CustomColor, gray, error } from './core';

const primary: CustomColor = {
  0: '#000000',
  10: '#853b25',
  20: '#ad5337',
  30: '#d66a4a',
  40: '#ff825c',
  50: '#ff9b7d',
  60: '#ffb49d',
  70: '#ffcdbe',
  80: '#ffd9ce',
  90: '#ffe6de',
  95: '#fff2ef',
  99: '#fffaf9',
  100: '#ffffff',
};

const secondary: CustomColor = {
  0: '#000000',
  10: '#8b4427',
  20: '#9b5234',
  30: '#bd6f4e',
  40: '#de8b68',
  50: '#ffa782',
  60: '#ffb99b',
  70: '#ffcab4',
  80: '#ffdccd',
  90: '#ffe5d9',
  95: '#ffede6',
  99: '#fffaf9',
  100: '#ffffff',
};

const tertiary: CustomColor = {
  0: '#000000',
  10: '#101966',
  20: '#192599',
  30: '#2132cc',
  40: '#293eff',
  50: '#5465ff',
  60: '#7f8bff',
  70: '#a9b2ff',
  80: '#bfc5ff',
  90: '#d4d8ff',
  95: '#eaecff',
  99: '#fafaff',
  100: '#ffffff',
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      ...primary,
      contrastText: primary[100],
      dark: primary[30],
      light: primary[50],
      main: primary[40],
    },
    secondary: {
      ...secondary,
      contrastText: secondary[100],
      dark: secondary[30],
      light: secondary[50],
      main: secondary[40],
    },
    tertiary: {
      ...tertiary,
      contrastText: tertiary[100],
      dark: tertiary[30],
      light: tertiary[50],
      main: tertiary[40],
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },
    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      tertiary: tertiary[40],
      tertiaryContainer: tertiary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3: 'rgba(128, 171, 255, 0.22)',
      surface4: 'rgba(128, 171, 255, 0.32)',
      surface5: 'rgba(89, 143, 241, 0.4)',
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
  },
};

export const trustPaisa = createTheme(core, theme);
