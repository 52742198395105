import { createTheme, ThemeOptions } from '@mui/material/styles';

import { core, CustomColor, gray, error } from './core';

const primary: CustomColor = {
  0: '#000000',
  10: '#001e30',
  20: '#00344f',
  30: '#004b71',
  40: '#006494',
  50: '#007eb9',
  60: '#0599df',
  70: '#41b4fc',
  80: '#8ecdff',
  90: '#cbe6ff',
  95: '#e6f2ff',
  99: '#fcfcff',
  100: '#ffffff',
};

const secondary: CustomColor = {
  0: '#000000',
  10: '#001552',
  20: '#002682',
  30: '#0038b6',
  40: '#2651d7',
  50: '#466cf1',
  60: '#6a89ff',
  70: '#91a7ff',
  80: '#b7c4ff',
  90: '#dde1ff',
  95: '#efefff',
  99: '#fefbff',
  100: '#ffffff',
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      ...primary,
      contrastText: primary[100],
      dark: primary[30],
      light: primary[50],
      main: primary[40],
    },
    secondary: {
      ...secondary,
      contrastText: secondary[100],
      dark: secondary[30],
      light: secondary[50],
      main: secondary[40],
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },

    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3:
        'linear-gradient(0deg, rgba(0, 100, 148, 0.08), rgba(0, 100, 148, 0.08)), #eff4fc',
      surface4:
        'linear-gradient(0deg, rgba(0, 152, 225, 0.15), rgba(0, 152, 225, 0.15)), #e9eff8',
      surface5:
        'linear-gradient(0deg, rgba(0, 100, 148, 0.18), rgba(0, 100, 148, 0.18)), #eff4fc',
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
  },
};

export const myvay = createTheme(core, theme);
