import { createTheme, ThemeOptions } from '@mui/material/styles';

import { core, CustomColor, gray, error } from './core';

const primary: CustomColor = {
  0: '#000000',
  10: '#034c29',
  20: '#05723d',
  30: '#069852',
  40: '#08be66',
  50: '#36cb83',
  60: '#64d8a0',
  70: '#93e4bd',
  80: '#aaebcb',
  90: '#c1f1da',
  95: '#d8f8e8',
  99: '#effef7',
  100: '#ffffff',
};

const secondary: CustomColor = {
  0: '#000000',
  10: '#1c603f',
  20: '#24754e',
  30: '#369f6c',
  40: '#47c98a',
  50: '#58f3a8',
  60: '#79f5b9',
  70: '#9bf8cb',
  80: '#bcfadc',
  90: '#cdfbe5',
  95: '#defdee',
  99: '#eefef6',
  100: '#ffffff',
};

const tertiary: CustomColor = {
  0: '#000000',
  10: '#101966',
  20: '#192599',
  30: '#2132cc',
  40: '#293eff',
  50: '#5465ff',
  60: '#7f8bff',
  70: '#a9b2ff',
  80: '#bfc5ff',
  90: '#d4d8ff',
  95: '#eaecff',
  99: '#fafaff',
  100: '#ffffff',
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      ...primary,
      contrastText: primary[100],
      dark: primary[30],
      light: primary[50],
      main: primary[40],
    },
    secondary: {
      ...secondary,
      contrastText: secondary[100],
      dark: secondary[30],
      light: secondary[50],
      main: secondary[40],
    },
    tertiary: {
      ...tertiary,
      contrastText: tertiary[100],
      dark: tertiary[30],
      light: tertiary[50],
      main: tertiary[40],
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },

    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      tertiary: tertiary[40],
      tertiaryContainer: tertiary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3:
        'linear-gradient(0deg, rgba(19, 153, 0, 0.04), rgba(19, 153, 0, 0.04)), #f4f4f4',
      surface4:
        'linear-gradient(0deg, rgba(19, 153, 0, 0.07), rgba(19, 153, 0, 0.07)), #f4f4f4',
      surface5:
        'linear-gradient(0deg, rgba(19, 153, 0, 0.1), rgba(19, 153, 0, 0.1)), #f4f4f4',
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
  },
};

export const ceyloan = createTheme(core, theme);
