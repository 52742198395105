import {
  Box,
  Dialog,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ActionSheetCloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box position="absolute" top={16} right={16}>
      <IconButton onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const ActionSheetDragIndicator = () => {
  return (
    <Box
      position="sticky"
      top={0}
      left={0}
      right={0}
      py={1}
      display="flex"
      justifyContent="center"
    >
      <Box width={32} height={4} bgcolor="#d1d4db" borderRadius={16} />
    </Box>
  );
};

const Container = ({
  children,
  isOpen,
  onClose,
  onOpen,
}: {
  children?: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  if (isDesktop) {
    return (
      <Dialog
        BackdropProps={{ sx: { background: 'rgba(17, 24, 40, 0.8)' } }}
        fullWidth={true}
        onClose={onClose}
        open={isOpen}
        maxWidth="md"
      >
        <ActionSheetCloseButton onClick={onClose} />
        {children}
      </Dialog>
    );
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      BackdropProps={{ sx: { background: 'rgba(17, 24, 40, 0.8)' } }}
      disableSwipeToOpen={true}
      onClose={onClose}
      onOpen={onOpen}
      open={isOpen}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: '80%',
        },
      }}
    >
      <ActionSheetDragIndicator />
      <Box>{children}</Box>
    </SwipeableDrawer>
  );
};

export type ActionSheetProps = {
  children?: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export const ActionSheet = ({
  children,
  isOpen,
  onClose,
  onOpen,
}: ActionSheetProps) => {
  return (
    <Container isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {children}
    </Container>
  );
};
