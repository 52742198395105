import { Box, Grid, Paper, Typography } from '@mui/material';
import Marquee from 'react-fast-marquee';
import Image from 'next/image';

import { SectionOuter } from '@/shared/ui';

interface IBlockPartnersProps {
  partnersList?: any[];
  title?: string;
}

export const BlockPartners = (props: IBlockPartnersProps) => {
  const { partnersList, title } = props;

  const getPartnersList = (list?: any[]) => {
    if (!list) return [];

    return list
      .filter((partner) => !!partner?.attributes?.logo?.data?.attributes?.url)
      .map((partner) => ({
        id: partner.id,
        alt: partner?.attributes?.logo?.data?.attributes?.alt || 'Partner logo',
        src: partner?.attributes?.logo?.data?.attributes?.url as string,
      }));
  };

  const partnersLogos = getPartnersList(partnersList);

  const GRID_SPACING = 2;

  const partnersCards = partnersLogos?.map((item) => {
    const layout = (
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          borderRadius: 2,
          bgcolor: 'neutral.100',
          width: {
            xs: 270,
            md: 380,
          },
          height: {
            xs: 100,
            md: 85,
          },
          '& img': {
            maxHeight: '100%',
            maxWidth: '64%',
            objectFit: 'contain',
          },
        }}
      >
        <Image alt={item.alt} src={item.src} width={243} height={85} />
      </Paper>
    );

    return (
      <Box key={item.id} px={GRID_SPACING / 2}>
        {layout}
      </Box>
    );
  });

  return (
    <SectionOuter>
      <Grid container spacing={GRID_SPACING}>
        {!!title && (
          <Grid item xs={12}>
            <Box mb={3 - GRID_SPACING}>
              <Typography variant="headline4" color="common.onPrimaryContainer">
                {title}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Marquee gradientWidth={50} speed={40} autoFill>
            {partnersCards}
          </Marquee>
        </Grid>
      </Grid>
    </SectionOuter>
  );
};
