import React from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';

import { Parser, ReplacementsMap } from '@/shared/ui';
import { FooterData } from '@/shared/api/common-api';

type FooterContactsProps = Pick<FooterData, 'footerContact'>;

export const FooterContacts = (props: FooterContactsProps) => {
  const contactsReplacementsMap: ReplacementsMap = {
    p: (domNode: Element, options: HTMLReactParserOptions) => {
      return (
        <Typography variant="text6" color="neutral.90" component="span">
          {domToReact(domNode.children, options)}
        </Typography>
      );
    },
    a: (domNode: Element, options: HTMLReactParserOptions) => {
      const href = domNode.attribs['href'];
      return (
        <Typography
          component={Link}
          href={href}
          variant="headline6"
          sx={{
            color: 'secondary.50',
            textDecoration: 'none',
            ['&:hover']: {
              textDecoration: 'underline',
            },
          }}
        >
          {domToReact(domNode.children, options)}
        </Typography>
      );
    },
  };

  if (!props.footerContact?.length) return null;

  return (
    <>
      {props.footerContact.map((contact) => (
        <Stack
          direction="row"
          alignItems="flex-start"
          spacing={1}
          sx={{ mt: 3 }}
          key={contact.id}
        >
          <Box mt={1}>
            <Image
              src={contact.icon?.data?.attributes?.url}
              alt={contact.icon?.data?.attributes?.alternativeText || ''}
              width={20}
              height={20}
            />
          </Box>

          <Typography component="div">
            <Typography
              variant="text6"
              component="span"
              sx={{
                mr: 1,
              }}
            >
              {contact.title}
            </Typography>
            <Parser data={contact.contact} redefine={contactsReplacementsMap} />
          </Typography>
        </Stack>
      ))}
    </>
  );
};
