import * as React from 'react';

import {
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from '@mui/material/styles';
import { alpha } from '@mui/material';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography';

export interface CustomColor {
  0: string;
  10: string;
  20: string;
  30: string;
  40: string;
  50: string;
  60: string;
  70: string;
  80: string;
  90: string;
  95: string;
  99: string;
  100: string;
}

declare module '@mui/material/styles' {
  export interface TypographyVariants {
    headline1: React.CSSProperties;
    headline2: React.CSSProperties;
    headline3: React.CSSProperties;
    headline4: React.CSSProperties;
    headline5: React.CSSProperties;
    headline6: React.CSSProperties;
    headline7: React.CSSProperties;
    headline8: React.CSSProperties;
    headline9: React.CSSProperties;
    headline10: React.CSSProperties;
    text1: React.CSSProperties;
    text2: React.CSSProperties;
    text3: React.CSSProperties;
    text4: React.CSSProperties;
    text5: React.CSSProperties;
    text6: React.CSSProperties;
    text7: React.CSSProperties;
    text8: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  export interface TypographyVariantsOptions {
    headline1?: React.CSSProperties;
    headline2?: React.CSSProperties;
    headline3?: React.CSSProperties;
    headline4?: React.CSSProperties;
    headline5?: React.CSSProperties;
    headline6?: React.CSSProperties;
    headline7?: React.CSSProperties;
    headline8?: React.CSSProperties;
    headline9?: React.CSSProperties;
    headline10?: React.CSSProperties;
    text1?: React.CSSProperties;
    text2?: React.CSSProperties;
    text3?: React.CSSProperties;
    text4?: React.CSSProperties;
    text5?: React.CSSProperties;
    text6?: React.CSSProperties;
    text7?: React.CSSProperties;
    text8?: React.CSSProperties;
  }

  export interface PaletteColor extends CustomColor {}

  export interface Color extends CustomColor {}

  export interface Palette extends CustomPalette {}

  export interface PaletteOptions extends CustomPaletteOptions {}

  export interface CommonColors {
    background: string;
    error: string;
    errorContainer: string;
    onBackground: string;
    onError: string;
    onErrorContainer: string;
    onPrimary: string;
    onPrimaryContainer: string;
    onSecondary: string;
    onSecondaryContainer: string;
    onSurface: string;
    onSurfaceVariant: string;
    onTertiary: string;
    onTertiaryContainer: string;
    outline: string;
    primary: string;
    primaryContainer: string;
    secondary: string;
    secondaryContainer: string;
    surface: string;
    surface1: string;
    surface2: string;
    surface3: string;
    surface4: string;
    surface5: string;
    surfaceVariant: string;
    tertiary: string;
    tertiaryContainer: string;
    inverseSurface: string;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    animated: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    headline1: true;
    headline2: true;
    headline3: true;
    headline4: true;
    headline5: true;
    headline6: true;
    headline7: true;
    headline8: true;
    headline9: true;
    headline10: true;
    text1: true;
    text2: true;
    text3: true;
    text4: true;
    text5: true;
    text6: true;
    text7: true;
    text8: true;
  }
}

export interface CustomPalette {
  primary: PaletteColor;
  secondary: PaletteColor;
  tertiary: PaletteColor;
  neutral: PaletteColor;
  neutralVariant: PaletteColor;
}

export interface CustomPaletteOptions {
  primary?: PaletteColorOptions;
  secondary?: PaletteColorOptions;
  tertiary?: PaletteColorOptions;
  neutral?: PaletteColorOptions;
  neutralVariant?: PaletteColorOptions;
}

declare module '@mui/material' {
  export interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    tertiary: true;
    neutral: true;
    neutralVariant: true;
  }
}

export const gray: CustomColor = {
  0: '#000000',
  10: '#0f131a',
  20: '#2a2d34',
  30: '#41454d',
  40: '#595d66',
  50: '#737780',
  60: '#8e9299',
  70: '#aaadb3',
  80: '#c6c8cc',
  90: '#e1e3e6',
  95: '#f0f1f2',
  99: '#fbfbfc',
  100: '#ffffff',
};

export const error: CustomColor = {
  0: '#000000',
  10: '#41000c',
  20: '#690005',
  30: '#93000a',
  40: '#ef3030',
  50: '#de3730',
  60: '#ff5449',
  70: '#ff897d',
  80: '#ffb4ab',
  90: '#ffc1c0',
  95: '#ffedea',
  99: '#fffbff',
  100: '#ffffff',
};

export const core: ThemeOptions = createTheme({
  palette: {
    neutral: {
      ...gray,
      contrastText: gray[100],
      dark: gray[30],
      light: gray[50],
      main: gray[40],
    },
    neutralVariant: {
      ...gray,
      contrastText: gray[100],
      dark: gray[30],
      light: gray[50],
      main: gray[40],
    },
  },
  shadows: [
    'none',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 21px 0px rgba(0, 0, 0, 0.04)',
  ],
  shape: {
    borderRadius: 8,
  },
  typography: {
    headline1: { fontWeight: 700, fontSize: '3.125rem', lineHeight: '120%' },
    headline2: { fontWeight: 700, fontSize: '2.5rem', lineHeight: '120%' },
    headline3: { fontWeight: 700, fontSize: '2rem', lineHeight: '120%' },
    headline4: { fontWeight: 700, fontSize: '1.75rem', lineHeight: '130%' },
    headline5: { fontWeight: 700, fontSize: '1.375rem', lineHeight: '130%' },
    headline6: { fontWeight: 700, fontSize: '1.25rem', lineHeight: '130%' },
    headline7: { fontWeight: 700, fontSize: '1.125rem', lineHeight: '130%' },
    headline8: { fontWeight: 700, fontSize: '1rem', lineHeight: '130%' },
    headline9: { fontWeight: 700, fontSize: '0.875rem', lineHeight: '130%' },
    headline10: { fontWeight: 700, fontSize: '0.75rem', lineHeight: '130%' },

    text1: { fontWeight: 400, fontSize: '2rem', lineHeight: '120%' },
    text2: { fontWeight: 400, fontSize: '1.75rem', lineHeight: '130%' },
    text3: { fontWeight: 400, fontSize: '1.375rem', lineHeight: '130%' },
    text4: { fontWeight: 400, fontSize: '1.25rem', lineHeight: '140%' },
    text5: { fontWeight: 400, fontSize: '1.125rem', lineHeight: '140%' },
    text6: { fontWeight: 400, fontSize: '1rem', lineHeight: '140%' },
    text7: { fontWeight: 400, fontSize: '0.875rem', lineHeight: '140%' },
    text8: { fontWeight: 400, fontSize: '0.75rem', lineHeight: '140%' },

    fontFamily: 'inherit',
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.short,
          }),
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
        variant: 'contained',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          fontWeight: 700,
          fontSize: '1rem',
          lineHeight: '130%',
          textTransform: 'none',
        }),
        containedPrimary: ({ theme }) => ({
          background: theme.palette.primary[40],
          '&:hover': {
            background: theme.palette.neutral[20],
          },
        }),
        containedSecondary: ({ theme }) => ({
          background: theme.palette.neutral[100],
          color: theme.palette.neutral[20],
          '&:hover': {
            background: theme.palette.neutral[20],
            borderColor: theme.palette.neutral[20],
            color: theme.palette.neutral[100],
          },
        }),
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.neutral[90],
          color: theme.palette.neutral[20],
          '&:hover': {
            background: theme.palette.neutral[20],
            borderColor: theme.palette.neutral[20],
            color: theme.palette.neutral[100],
          },
          '&:active': {
            background: theme.palette.primary[40],
            borderColor: theme.palette.primary[40],
            color: theme.palette.neutral[100],
          },
        }),
        outlinedSecondary: ({ theme }) => ({
          borderColor: theme.palette.neutral[90],
          color: theme.palette.neutral[100],
          '&:hover': {
            background: theme.palette.neutral[20],
            borderColor: theme.palette.neutral[20],
            color: theme.palette.neutral[100],
          },
          '&:active': {
            background: theme.palette.secondary[40],
            borderColor: theme.palette.secondary[40],
            color: theme.palette.neutral[100],
          },
        }),
        sizeSmall: ({ theme }) => ({
          paddingTop: theme.spacing(1.25),
          paddingBottom: theme.spacing(1.25),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        }),
        sizeMedium: ({ theme }) => ({
          paddingTop: theme.spacing(1.75),
          paddingBottom: theme.spacing(1.75),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(23),
        }),
        sizeLarge: ({ theme }) => ({
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.spacing(0.75),
          background: theme.palette.neutral[20],
          color: theme.palette.neutral[100],
        }),
        sizeSmall: () => ({
          height: 30,
        }),
        sizeMedium: () => ({
          height: 32,
        }),
        clickable: ({ theme }) => ({
          '&:hover': {
            background: theme.palette.neutral[50],
          },
        }),
        colorPrimary: ({ theme }) => ({
          background: theme.palette.primary[40],
        }),
        outlined: ({ theme }) => ({
          background: 'transparent',
          borderColor: theme.palette.neutral[90],
          color: theme.palette.neutral[50],
        }),
        outlinedPrimary: ({ theme }) => ({
          borderColor: theme.palette.primary[40],
          color: theme.palette.primary[40],
        }),
        labelSmall: ({ theme }) => ({
          paddingLeft: theme.spacing(1.25),
          paddingRight: theme.spacing(1.25),
        }),
        labelMedium: ({ theme }) => ({
          paddingLeft: theme.spacing(2.125),
          paddingRight: theme.spacing(2.125),
        }),
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'animated' },
          style: ({ theme }) => ({
            transition: 'all .3s ease',
            '&:hover': {
              color: gray[100],
              background: gray[20],
              '& .MuiChip-filled': {
                background: theme.palette.primary[40],
              },
            },
          }),
        },
      ],
      styleOverrides: {
        root: {},
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(2),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          background: theme.palette.common.surface1,
        },
      }),
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.neutral[95],
        }),
        rounded: ({ theme }) => ({
          borderRadius: theme.spacing(2.5),
        }),
        outlined: ({ theme }) => ({
          background: theme.palette.neutral[100],
        }),
        elevation1: ({ theme }) => ({
          boxShadow: theme.shadows[0],
        }),
        elevation8: ({ theme }) => ({
          boxShadow: theme.shadows[1],
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: ({ theme }) => ({
          padding: theme.spacing(1, 1.5),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: ({ theme }) => ({
          padding: theme.spacing(2, 1.5),
          '&.MuiInputBase-inputSizeSmall': {
            padding: theme.spacing(1, 1.5),
          },
        }),
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
          boxShadow: `0 0 0 1px ${theme.palette.neutral[90]}`,
          background: theme.palette.common.white,
          color: theme.palette.common.onBackground,
          transition: theme.transitions.create('box-shadow', {
            duration: theme.transitions.duration.short,
          }),

          ':hover': {
            backgroundColor: theme.palette.common.white,
          },

          ':before': {
            display: 'none',
          },

          ':after': {
            display: 'none',
          },

          '&.Mui-focused': {
            boxShadow: `0 0 0 2px ${theme.palette.secondary[80]}`,
            background: theme.palette.common.white,
          },

          '&.MuiInputBase-colorError': {
            boxShadow: `0 0 0 1px ${theme.palette.error[60]}`,
          },

          '&.Mui-disabled': {
            background: theme.palette.neutralVariant[95],
          },

          '&.Mui-error': {
            boxShadow: `0 0 0 2px ${theme.palette.error.main}`,
          },
        }),
        input: ({ theme }) => ({
          padding: theme.spacing(2, 1.5),
          background: 'transparent',
          fontSize: '1rem',
          '&.MuiInputBase-inputSizeSmall': {
            padding: theme.spacing(1, 1.5),
          },
          '&:focus': {
            background: 'transparent',
          },
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: theme.palette.neutral[60],
            opacity: 1,
          },
        }),
      },
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'relative',
          fontSize: '0.875rem',
          transform: 'none',
          marginBottom: theme.spacing(0.5),
          color: theme.palette.common.onSurface,
          padding: theme.spacing(0, 1.5),
          '&.Mui-disabled': {
            color: theme.palette.common.onSurface,
          },
          '&.Mui-focused': {
            fontSize: '0.875rem',
            color: theme.palette.common.onPrimaryContainer,
          },
        }),
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          paddingRight: '0.75rem',
          paddingLeft: '0.75rem',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-active': {
            color: theme.palette.primary.main,
          },

          '&.Mui-completed': {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.9375rem',
        },
        iconContainer: {
          '& .MuiStepIcon-root': {
            width: '2rem',
            height: '2rem',
          },
        },
      },
    },
    MuiStepper: {
      defaultProps: {
        connector: null,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headline1: 'h1',
          headline2: 'h2',
          headline3: 'h3',
          headline4: 'h4',
          headline5: 'h5',
          headline6: 'h6',
          text1: 'p',
          text2: 'p',
          text3: 'p',
          text4: 'p',
          text5: 'p',
          text6: 'p',
          text7: 'p',
        } as Record<keyof TypographyPropsVariantOverrides, string>,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.neutral[100],
          boxShadow: 'none',
          borderBottomWidth: 1,
          borderBottomColor: alpha(theme.palette.common.outline, 0.4),
          borderBottomStyle: 'solid',
          '&:first-child': {
            borderRadius: 0,
          },
          '&:last-child': {
            borderRadius: 0,
            borderBottomWidth: 0,
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5, 2),
        }),
        content: ({ theme }) => ({
          '&.Mui-expanded': {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(0.5),
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0, 3, 3, 3),
        }),
      },
    },
  },
});
