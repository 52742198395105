import { useTranslations } from 'use-intl';
import { useConfig } from '@/shared/hooks';
import { ActionSheet } from '@/shared/ui';
import { Box, Stack, Typography } from '@mui/material';
import LoanAgreementNumberWeb from '@/assets/images/loan-agreement-number-web.png';
import LoanAgreementNumberMobile from '@/assets/images/loan-agreement-number-mobile.png';
import React from 'react';
import { LoanAgreementGuideItem } from './loan-agreement-guide-item';

export const GetLoanAgreementNumberGuide = ({
  isOpen,
  onClose,
  onOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) => {
  const t = useTranslations();
  const config = useConfig();

  const webSteps: string[] = t.raw(
    'repayment.getLoanAgreementNumberInstructionWebSteps',
  );
  const mobileSteps: string[] = t.raw(
    'repayment.getLoanAgreementNumberInstructionMobileSteps',
  );

  return (
    <ActionSheet isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <Box p={3} pt={{ xs: 0.5, md: 3 }}>
        <Typography mb={3} mr={3} component="p" variant="h6" fontWeight="bold">
          {t('repayment.getLoanAgreementNumberTitle')}
        </Typography>
        <Stack spacing={3}>
          <LoanAgreementGuideItem
            imageSrc={
              config?.countryCode === 'LK'
                ? LoanAgreementNumberWeb.src
                : undefined
            }
            steps={webSteps}
            title={t('repayment.getLoanAgreementNumberInstructionWebTitle', {
              domain: config?.domain,
            })}
          />
          <LoanAgreementGuideItem
            imageSrc={
              config?.countryCode === 'LK'
                ? LoanAgreementNumberMobile.src
                : undefined
            }
            steps={mobileSteps}
            title={t('repayment.getLoanAgreementNumberInstructionMobileTitle', {
              appName: config?.title,
            })}
          />
        </Stack>
      </Box>
    </ActionSheet>
  );
};
