import React from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslations } from 'use-intl';

import { RepaymentRequisitesRow } from '@/shared/api/repayment-guide-api';

export const RepaymentRequisitesV2 = ({
  rows,
  onHelpIconClick,
}: {
  rows?: RepaymentRequisitesRow[];
  onHelpIconClick: () => void;
}) => {
  return (
    <Box>
      <List sx={{ p: 0 }}>
        {rows?.map((row, index, array) => (
          <React.Fragment key={row.id}>
            <ListItem
              alignItems="flex-start"
              sx={{
                px: 0,
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="headline8"
                    color="neutral.50"
                    component="div"
                    mb={0.625}
                  >
                    {row.title}
                  </Typography>
                }
                secondary={
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    component="span"
                  >
                    <Typography variant="text6" color="neutral.50">
                      {row.description}
                    </Typography>
                    {row.show_modal && (
                      <IconButton
                        size="small"
                        onClick={onHelpIconClick}
                        sx={{ p: 0 }}
                      >
                        <HelpIcon />
                      </IconButton>
                    )}
                  </Stack>
                }
              />
            </ListItem>
            {index !== array.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export const WarningPostPaymentV2 = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const t = useTranslations();

  return (
    <Alert
      severity="warning"
      variant="outlined"
      sx={{
        position: 'relative',

        '.MuiAlert-icon': {
          position: 'absolute',
          padding: '10px 0',
        },

        '.MuiAlert-message': {
          fontSize: 16,
          color: '#111614',
        },
      }}
    >
      <AlertTitle
        sx={{
          ml: 4,
          mb: 2,
          fontSize: 20,
          fontWeight: 'bold',
          color: 'rgb(102, 60, 0)',
        }}
      >
        {t('repayment.warningPostPaymentTitle')}
      </AlertTitle>
      <Box>{children}</Box>
    </Alert>
  );
};
