import { CountryCode } from '@/shared/types';
import { countryCode } from '@/shared/config';

export const COUNTRY: Record<CountryCode, boolean> = {
  ID: countryCode === CountryCode.ID,
  LK: countryCode === CountryCode.LK,
  MY: countryCode === CountryCode.MY,
  VN: countryCode === CountryCode.VN,
  IN: countryCode === CountryCode.IN,
  BD: countryCode === CountryCode.BD,
};
