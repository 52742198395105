import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { getImageBaseProps } from '@/utils';
import { Parser } from '@/shared/ui';
import { useCommonApi } from '@/shared/contexts';

export const AmmanaFooterLegal = () => {
  const commonApiContext = useCommonApi();

  const contentData =
    commonApiContext?.layoutSettings?.attributes?.ammanaFooterLegal;

  if (!contentData) {
    return null;
  }

  return (
    <Box
      mt={{
        xs: 4,
        md: 8,
      }}
    >
      <Stack
        flexDirection={{
          md: 'row',
        }}
        justifyContent="space-between"
        alignItems={{
          xs: 'stretch',
          md: 'center',
        }}
      >
        <Box
          py={{
            xs: 3,
            md: 0,
          }}
        >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent={{
              xs: 'space-between',
              md: 'flex-start',
            }}
          >
            <Typography
              variant="text6"
              color="neutral.90"
              component="div"
              mb={{ xs: 2.5 }}
              mr={{ md: 1.25 }}
            >
              {contentData.text1}
            </Typography>
            {contentData.image1?.data ? (
              <Image {...getImageBaseProps(contentData.image1)} />
            ) : null}
          </Stack>
        </Box>
        <Box
          py={{
            xs: 3,
            md: 0,
          }}
          ml={{
            md: 10,
          }}
          sx={{
            borderTopColor: 'neutral.50',
            borderTopStyle: 'solid',
            borderTopWidth: {
              xs: 1,
              md: 0,
            },
            flexGrow: {
              md: 1,
            },
          }}
        >
          <Stack
            flexDirection={{
              md: 'row',
            }}
            alignItems="center"
            justifyContent={{ xs: 'space-between', md: 'flex-start' }}
          >
            <Box mb={{ xs: 2.5 }} mr={{ md: 1.25 }}>
              <Typography variant="text6" color="neutral.90" component="div">
                {contentData.text2}
              </Typography>
              <Typography variant="text6" color="neutral.50" component="div">
                {contentData.subtext2}
              </Typography>
            </Box>
            {contentData.image2?.data ? (
              <Image
                {...getImageBaseProps(contentData.image2)}
                style={{ objectFit: 'contain' }}
              />
            ) : null}
          </Stack>
        </Box>
        <Box
          ml={{ md: 2.5 }}
          py={{
            xs: 3,
            md: 0,
          }}
          sx={{
            borderTopColor: 'neutral.50',
            borderTopStyle: 'solid',
            borderTopWidth: {
              xs: 1,
              md: 0,
            },
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          {contentData.image3?.data ? (
            <Image
              {...getImageBaseProps(contentData.image3)}
              style={{ objectFit: 'contain' }}
            />
          ) : null}
        </Box>
      </Stack>

      {contentData.terms ? (
        <Box
          pt={{
            xs: 3,
            md: 4.375,
          }}
          mt={{
            xs: 0,
            md: 3.75,
          }}
          sx={{
            borderTopColor: 'neutral.50',
            borderTopStyle: 'solid',
            borderTopWidth: {
              xs: 0,
              md: 1,
            },
            color: 'neutral.90',
            fontSize: 12,
          }}
        >
          <Parser data={contentData.terms} />
        </Box>
      ) : null}
    </Box>
  );
};
