import { createTheme, ThemeOptions } from '@mui/material/styles';

import { core, CustomColor, gray, error } from './core';

const primary: CustomColor = {
  0: '#000000',
  10: '#8a2c29',
  20: '#b1413e',
  30: '#d75652',
  40: '#fe6b67',
  50: '#fe8985',
  60: '#fea6a4',
  70: '#ffc4c2',
  80: '#ffd3d1',
  90: '#ffe1e1',
  95: '#fff0f0',
  99: '#fffbfb',
  100: '#ffffff',
};

const secondary: CustomColor = {
  0: '#000000',
  10: '#a6302c',
  20: '#b33f3b',
  30: '#cc5c59',
  40: '#e67a76',
  50: '#ff9794',
  60: '#ffacaa',
  70: '#ffc1bf',
  80: '#ffd5d4',
  90: '#ffe0df',
  95: '#ffeaea',
  99: '#fff9f8',
  100: '#ffffff',
};

const tertiary: CustomColor = {
  0: '#000000',
  10: '#101966',
  20: '#192599',
  30: '#2132cc',
  40: '#293eff',
  50: '#5465ff',
  60: '#7f8bff',
  70: '#a9b2ff',
  80: '#bfc5ff',
  90: '#d4d8ff',
  95: '#eaecff',
  99: '#fafaff',
  100: '#ffffff',
};

const theme: ThemeOptions = {
  palette: {
    primary: {
      ...primary,
      contrastText: primary[100],
      dark: primary[30],
      light: primary[50],
      main: primary[40],
    },
    secondary: {
      ...secondary,
      contrastText: secondary[100],
      dark: secondary[30],
      light: secondary[50],
      main: secondary[40],
    },
    tertiary: {
      ...tertiary,
      contrastText: tertiary[100],
      dark: tertiary[30],
      light: tertiary[50],
      main: tertiary[40],
    },
    error: {
      ...error,
      contrastText: error[100],
      dark: error[30],
      light: error[50],
      main: error[40],
    },
    common: {
      background: gray[100],
      error: error[40],
      errorContainer: error[90],
      onBackground: gray[10],
      onError: gray[100],
      onErrorContainer: gray[10],
      onPrimary: primary[100],
      onPrimaryContainer: gray[10],
      onSecondary: secondary[100],
      onSecondaryContainer: gray[10],
      onSurface: gray[10],
      onSurfaceVariant: gray[30],
      outline: gray[50],
      primary: primary[40],
      primaryContainer: primary[90],
      secondary: secondary[40],
      secondaryContainer: secondary[90],
      tertiary: tertiary[40],
      tertiaryContainer: tertiary[90],
      surface: gray[100],
      surface1: gray[99],
      surface2: gray[95],
      surface3: '#f1edfc',
      surface4: '#eae6fa',
      surface5: '#e5e1fa',
      surfaceVariant: gray[90],
      inverseSurface: gray[20],
    },
  },
};

export const tambadana = createTheme(core, theme);
