import React from 'react';
import NextLink from 'next/link';
import { Box, Grid, Link } from '@mui/material';
import { NavLink } from '@/shared/lib/navigations';

export type FooterNavMenuProps = {
  links?: NavLink[];
};

export const FooterNavMenu = ({ links }: FooterNavMenuProps) => {
  if (!links) {
    console.error('Footer nav items does not exists');
    return null;
  }

  return (
    <Box
      mt={{
        xs: 3,
        md: 0,
      }}
    >
      <Grid container spacing={3}>
        {links.map(({ href, text, target }, i) => (
          <Grid item xs={6} md={4} key={i}>
            <Link
              key={i}
              component={NextLink}
              variant="text6"
              color="inherit"
              href={href}
              underline="none"
              target={target}
              sx={{
                color: 'neutral.50',
                '&:hover': {
                  color: 'secondary.50',
                },
              }}
            >
              {text}
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
