import React from 'react';
import { Box, Typography } from '@mui/material';

export const StepHeading = ({
  children,
  index,
}: {
  children: React.ReactNode | React.ReactNode[];
  index: number;
}) => {
  return (
    <Box
      mb={2}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Typography variant="headline7" color="neutral.20" sx={{ width: 33 }}>
        {index}.
      </Typography>
      <Typography component="h3" variant="headline7">
        {children}
      </Typography>
    </Box>
  );
};
